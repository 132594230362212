<template>
  <div>
    <h3
      class="text-xl leading-6 font-medium text-gray-900 mb-2"
      data-test="credits-heading"
    >
      Credits
    </h3>
    <DynamicLayoutField
      question-id="credits"
      :questions="questions"
      :document-value="course"
      :value="course"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DynamicLayoutField from '@/components/DynamicLayoutField'

export default {
  components: { DynamicLayoutField },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('settings', ['coursePageTemplate']),
    questions() {
      return Object.values(this.coursePageTemplate.questions)
    },
  },
}
</script>

<style scoped>
div >>> .field-component .field-label {
  @apply font-medium text-base;
}
</style>
