import { assembleUrl } from '@/utils/url'

export default class CoursesService {
  constructor($axios, $store) {
    this.axios = $axios
    this.school = $store.state.settings.school
    this.activeCatalog = $store.state.settings.activeCatalog
    this.effectiveDatesRange =
      $store.getters['settings/effectiveDatesRangeAsString']
    this.coursesUrl = `/cm/${this.school}/courses`

    this.coursesFilters = $store.state.settings.coursesFilters
    this.hasFilters =
      this.coursesFilters?.filters && this.coursesFilters?.filters.length > 0

    this.filters = this.hasFilters
      ? {
          ...this.coursesFilters,
          filters: this.coursesFilters.filters,
        }
      : {}
  }

  getCourses({
    query = '$filters',
    skip = 0,
    limit = 50000,
    filters = this.filters,
    department,
    columns = [],
    sortBy,
    courseGroupIds,
    formatDependents = false,
  }) {
    const skipParam = encodeURIComponent(skip)
    const queryParam = encodeURIComponent(query)
    const limitParam = encodeURIComponent(limit)
    let url = assembleUrl(`${this.coursesUrl}/search/${queryParam}`, {
      catalogId: this.activeCatalog,
      skip: skipParam,
      limit: limitParam,
      orderBy: sortBy,
      formatDependents,
      effectiveDatesRange: this.effectiveDatesRange,
      ...(columns.length ? { columns: columns.join(',') } : {}),
    })

    if (department) url += `&departments=${department}`

    return this.axios.$post(url, {
      ...filters,
      ...(courseGroupIds ? { courseGroupIds } : {}),
    })
  }

  getCourse(
    courseGroupId,
    {
      includeRelatedData = true,
      includeCrosslisted = true,
      includeCourseEquivalencies = true,
      columns,
      formatDependents = true,
      includeMappedDocumentItems = false,
    } = {}
  ) {
    const url = assembleUrl(`${this.coursesUrl}/search/$filters`, {
      courseGroupIds: courseGroupId,
      effectiveDatesRange: this.effectiveDatesRange,
      formatDependents,
      includeRelatedData,
      includeCrosslisted,
      includeCourseEquivalencies,
      includeMappedDocumentItems,
      includePending: false,
      returnResultsWithTotalCount: false,
      doNotDisplayAllMappedRevisionsAsDependencies: true,
      ...(columns ? { columns } : {}),
    })

    return this.axios.$get(url).then((data) => {
      return data.data[0]
    })
  }
}
