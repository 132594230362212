<template>
  <DynamicFieldsRenderer
    class="resource-preview"
    :fields="resourcePreviewFields"
    :questions="resourcePreviewQuestions"
    :document-value="value"
    :entity-type="entityType"
    :value="value"
  />
</template>

<script>
import DynamicFieldsRenderer from '@/components/contemporaryTheme/components/DynamicFieldsRenderer'
import useFeatureFlags from '@/composables/useFeatureFlags'
import { SECTION_SCHEDULING_REDESIGN } from '@/constants/featureFlags'

export default {
  components: { DynamicFieldsRenderer },
  props: {
    template: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    entityType: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { sectionSchedulingRedesign } = useFeatureFlags({
      sectionSchedulingRedesign: SECTION_SCHEDULING_REDESIGN,
    })

    return { sectionSchedulingRedesign }
  },
  computed: {
    resourcePreviewFields() {
      return this.template.template.reduce((fields, card) => {
        card.children.forEach((row) => {
          // previous design (table) is not compatible with being displayed on sidebar or in modal
          const rowItems = this.sectionSchedulingRedesign
            ? row.children
            : row.children.filter((field) => field.id !== 'courseSchedule')
          fields.push([...rowItems])
        })
        return fields
      }, [])
    },
    resourcePreviewQuestions() {
      return Object.values(this.template.questions)
    },
  },
}
</script>
<style scoped lang="scss">
.resource-preview ::v-deep .field-row {
  @apply border-b border-gray-300 pt-4 px-4;
  &:last {
    border: 0;
  }
}
.resource-preview ::v-deep .field-component {
  margin-bottom: 0px;
  @apply pb-4;
  .field-label {
    font-size: 0.75rem;
    @apply text-gray-600 mb-2;
  }
}
</style>
