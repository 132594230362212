<template>
  <AppModal
    v-if="details"
    :use-dimness="true"
    @close="$emit('close', false)"
  >
    <template #modal-header>
      <h3
        id="modal-headline"
        class="text-xl leading-6 font-medium text-gray-900"
        data-test="labelOrId"
      >
        {{ details.label || details.id }}
      </h3>
    </template>
    <template #modal-body>
      <!-- We want to hide hard coded description when we have preview templates enabled in the slot
      Since preview templates can also have description
      This means if we want to use ItemDetailsModal for anything that does not have preview templates
      Then we should modify the following if logic. -->
      <p
        v-if="!configurableResourcePreview"
        class="text-sm leading-5 text-gray-500"
        data-test="description"
        v-html="parseItemDescription(details.description) || 'No description'"
      >
      </p>
      <slot />
    </template>
    <template #modal-footer>
      <div class="flex items-center p-4">
        <button
          type="button"
          class="mr-2 flex-1 justify-center w-full rounded-md border border-transparent px-4 py-2 bg-theme-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-theme-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5 details-item-link"
          @click="$router.push(`/${details.path}/${encodeURIComponent(details.id)}`)"
        >
          View details
        </button>
        <button
          type="button"
          aria-label="Close modal"
          class="ml-2 flex-1 justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5 ui-font"
          @click="$emit('close', false)"
        >
          Close
        </button>
      </div>
    </template>
  </AppModal>
</template>

<script>
import AppModal from '@/components/AppModal'
import useFeatureFlags from '@/composables/useFeatureFlags'
import { CONFIGURABLE_RESOURCE_PREVIEW } from '@/constants/featureFlags'
export default {
  setup(props, { root }) {
    const { configurableResourcePreview } = useFeatureFlags({
      configurableResourcePreview: CONFIGURABLE_RESOURCE_PREVIEW,
    })
    return {
      configurableResourcePreview,
    }
  },
  components: { AppModal },
  model: { prop: 'details', event: 'close' },
  props: {
    details: {
      type: [Object, Boolean],
      required: true,
    },
  },
  methods: {
    parseItemDescription(description) {
      return description?.replaceAll('\n', '<br/>')
    },
  },
}
</script>

<style scoped>
.details-item-link {
  color: white !important;
}
.details-item-link:hover {
  text-decoration: none;
}
</style>
